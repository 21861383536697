import { FunctionComponent } from "react";
import { LastViewedObject } from "../../types/types";
import Page from "../Page";
import PersonList from "../PersonList";
import useFetchPage from "../hooks/useFetchPage";

interface Props {
  setLastViewed: (value: LastViewedObject) => void;
}

const Member: FunctionComponent<Props> = ({ setLastViewed }) => {
  const page = useFetchPage(setLastViewed);

  if (!page) {
    return null;
  }

  return (
    <Page title={page.frontmatter.title} description={page.content}>
      <PersonList />
    </Page>
  );
};

export default Member;
