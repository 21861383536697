import { FunctionComponent, useContext } from "react";
import { postUserPermission } from "../../http/chapterApi";
import SVGSave from "../../icons/Save.svg?react";
import { UserManagmentItem, UserPermission } from "../../types/apiTypes";
import { NotificationContext } from "../../utils";
import Icon from "../ui/Icon";
import styles from "./UserManagmentList.module.css";

interface Props {
  item: UserManagmentItem;
  users: UserManagmentItem[] | undefined;
  accountPermission: UserPermission;
  onSave: () => void;
}

const UserManagmentSaveButton: FunctionComponent<Props> = ({
  item,
  users,
  accountPermission,
  onSave,
}) => {
  const dispatch = useContext(NotificationContext);

  const handleSave = () => {
    if (!item.iamUser?.id || !item.permission)
      return dispatch("Speichern fehlgeschlagen: Bitte Daten eintragen");

    const isAlready = users?.some(
      (userItem) =>
        userItem.iamUser?.id === item.iamUser?.id ||
        (userItem.person?.personId &&
          userItem.person.personId === item.person?.personId),
    );

    if (isAlready)
      return dispatch("Speichern fehlgeschlagen: Benutzer existiert bereits");

    if (item.permission === "member" && !item.person?.personId) {
      return dispatch(
        "Speichern fehlgeschlagen: Es kann kein Mitglied ohne ausgewählten Funktionär abgespeichert werden",
      );
    }

    if (
      accountPermission.permission.toLowerCase() === "manager" &&
      item.permission.toLowerCase() === "admin"
    )
      return dispatch(
        "Speichern fehlgeschlagen: Sie sind zu dieser Aktion nicht berechtigt",
      );

    postUserPermission(item)
      .then((response) => {
        if (response?.ok) return onSave();

        throw new Error("Speichern fehlgeschlagen");
      })
      .catch((error: Error) => {
        dispatch(error.message);
      });
  };

  return (
    <button className={styles["save-button"]} onClick={handleSave}>
      <Icon glyph={SVGSave} className={styles["save-icon"]} />
    </button>
  );
};

export default UserManagmentSaveButton;
