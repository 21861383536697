import { FunctionComponent, useEffect, useState } from "react";
import { SingleValue } from "react-select";
import { getFunctionaries } from "../../http/chapterApi";
import {
  Functionary,
  ISelectOption,
  UserManagmentItem,
} from "../../types/apiTypes";
import { buildFunctionaryLabel } from "../../utils";
import SelectBox from "../ui/SelectBox";

interface Props {
  item: UserManagmentItem;
  onChange: (value: Functionary | undefined) => void;
}

const FunctionarySelect: FunctionComponent<Props> = ({ item, onChange }) => {
  const [functionaries, setFunctionaries] = useState<Functionary[]>([]);

  useEffect(() => {
    loadFunctionaries();
  }, []);

  const loadFunctionaries = async () => {
    const functionaries = await getFunctionaries();
    functionaries && setFunctionaries(functionaries);
  };

  const options: ISelectOption[] = functionaries
    .map((item) => ({
      value: String(item.personId),
      label: `${item.firstName} ${item.lastName} (${item.email})`,
    }))
    .sort((a: ISelectOption, b: ISelectOption) => (a.label > b.label ? 1 : -1));

  return (
    <SelectBox
      value={
        item.person
          ? {
              label: buildFunctionaryLabel(item) ?? "",
              value: String(item.person.personId),
            }
          : null
      }
      placeholder="Funktionär wählen"
      onChange={(value: SingleValue<ISelectOption>) => {
        const currentFunctionary = functionaries.find(
          (x) => String(x.personId) === value?.value,
        );
        onChange(currentFunctionary);
      }}
      options={[{ value: null, label: "Kein Funktionär" }, ...options]}
    />
  );
};

export default FunctionarySelect;
