import classNames from "classnames";
import { FunctionComponent, useContext } from "react";
import { getLogo, getTemplateFile } from "../http/chapterApi";
import SVGDocx from "../icons/Docx.svg?react";
import Download from "../icons/Download.svg?react";
import SVGFile from "../icons/File.svg?react";
import SvgXlsx from "../icons/Xlsx.svg?react";
import { FileType, ListItemData } from "../types/types";
import { DataContext } from "./AppRouter";
import styles from "./ListItem.module.css";
import Button from "./ui/Button";
import Icon from "./ui/Icon";

interface Props {
  file: string;
  isTemplates: boolean | undefined;
}

const getIcon = (type: FileType) => {
  switch (type) {
    case "dotx":
      return SVGDocx;
    case "xltx":
      return SvgXlsx;
    default:
      return SVGFile;
  }
};

const downloadFile = async (
  chapterId: number | undefined,
  file: string,
  isTemplates: boolean | undefined,
) => {
  const blob = isTemplates
    ? await getTemplateFile(chapterId, file)
    : await getLogo(chapterId);
  const url = blob && window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url ?? "";
  link.setAttribute("download", file);
  document.body.appendChild(link);
  link.click();
};

const ListItem: FunctionComponent<Props> = ({ file, isTemplates }) => {
  const dataSplit = file.split(".");
  const { chapter } = useContext(DataContext);

  const fileObject: ListItemData = {
    name: dataSplit[0] ?? "",
    extension: dataSplit[1] as FileType,
  };

  return (
    <div className={styles.row}>
      <div>
        <p className={styles.label}>{fileObject.name}</p>
      </div>
      <div>
        <p className={classNames(styles.label, styles["file-type"])}>
          <Icon
            className={styles["file-icon"]}
            glyph={getIcon(fileObject.extension)}
          />
          {fileObject.extension}
        </p>
      </div>
      <div>
        <Button
          buttonProps={{
            onClick: () => {
              downloadFile(chapter?.id, file, isTemplates);
            },
            className: styles["btn-download"],
          }}
        >
          <Icon className={styles["btn-icon"]} glyph={Download} />
          <span className={styles["btn-label"]}>Herunterladen</span>
        </Button>
      </div>
    </div>
  );
};

export default ListItem;
