import {
  Chapter,
  ChapterFunctionary,
  DistrictMember,
  EventType,
  Functionary,
  IamUser,
  Member,
  OrganizationType,
  UserManagmentItem,
  UserPermission,
} from "../types/apiTypes";

const baseUrl = "/api/v1";

export const getUsers = async () => {
  return await getData<UserManagmentItem[]>(`${baseUrl}/users`);
};

export const getEvents = async (id: string, year: number, month: number) => {
  const params = new URLSearchParams({
    year: String(year),
    month: String(month),
  });
  return await getData<EventType[]>(
    `${baseUrl}/users/${id}/events?${params.toString()}`,
  );
};

export const getPersonChapters = async (id: string) => {
  return await getData<Chapter[]>(`${baseUrl}/persons/${id}/chapters`);
};

export const getFunctionaries = async () => {
  return await getData<Functionary[]>(`${baseUrl}/functionaries`);
};

export const getOrganizationChart = async () => {
  return await getData<OrganizationType>(
    `${baseUrl}/functionaries/organization_chart`,
  );
};

export const getChapters = async () => {
  return await getData<Chapter[]>(`${baseUrl}/chapters`);
};

export const getChapterMembers = async (id: number) => {
  return await getData<Member[]>(`${baseUrl}/chapters/${id}/members`);
};

export const getChapterFunctionaries = async (id: number) => {
  return await getData<ChapterFunctionary[]>(
    `${baseUrl}/chapters/${id}/functionaries`,
  );
};

export const getPermissions = async (): Promise<UserPermission | null> => {
  return (await getData<UserPermission>(`${baseUrl}/permissions`)) ?? null;
};

export const postUserPermission = async (item: UserManagmentItem) => {
  return await postData(`${baseUrl}/users/${item.iamUser?.id}/permission`, {
    userId: item.iamUser?.id,
    personId: item.person?.personId,
    permission: item.permission,
  });
};

export const deleteUserPermission = async (id: string) => {
  return await doDelete(`${baseUrl}/users/${id}/permission`);
};

export const getIAMUsers = async (
  query?: string,
): Promise<IamUser[] | undefined> => {
  return await getData<IamUser[]>(
    `${baseUrl}/iam/users${query ? `?query=${query}` : ""}`,
  );
};

export const getPersonPicture = async (id: string) => {
  return await getBlob(`${baseUrl}/functionaries/${id}/picture`);
};

export const getTemplates = async () => {
  return await getData<string[]>(`${baseUrl}/templates`);
};

export const getDistrictsCommitteeList = async (id: number) => {
  return await getData<DistrictMember[]>(
    `${baseUrl}/districts/${id}/committee`,
  );
};

export const getTemplateFile = async (
  chaperId: number | undefined,
  filename: string,
) => {
  return await getBlob(`${baseUrl}/templates/${chaperId}/${filename}`);
};

export const getLogo = async (id: number | undefined) => {
  return await getBlob(`${baseUrl}/chapters/${id}/logo`);
};

export const getLogos = async (chapter: Chapter | undefined) => {
  const logo = await getLogo(chapter?.id);

  if (!logo) return;

  return ["Logo.png"];
};

export const getCommitteeExport = async (id: number) => {
  return await getBlob(`${baseUrl}/chapters/${id}/functionaries`, {
    Accept: "text/csv",
  });
};

export const getMemberExport = async (id: number) => {
  return await getBlob(`${baseUrl}/chapters/${id}/members`, {
    Accept: "text/csv",
  });
};

const getData = async <Response>(
  url: string,
): Promise<Response | undefined> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });
  if (response.status !== 200) return;
  return await response.json();
};

const postData = async (
  url: string,
  data?: object,
): Promise<Response | undefined> => {
  return await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const doDelete = async (url: string): Promise<Response | undefined> => {
  return await fetch(url, {
    method: "Delete",
    credentials: "include",
  });
};

const getBlob = async (
  url: string,
  headers?: Record<string, string>,
): Promise<Blob | undefined> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      ...headers,
    },
  });
  if (response.status !== 200) return;
  return await response.blob();
};
