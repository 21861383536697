import { useEffect, useState } from "react";
import { getChapterMembers } from "../../http/chapterApi";
import { Chapter, Member } from "../../types/apiTypes";

const useFetchMember = (chapter: Chapter | undefined) => {
  const [member, setMember] = useState<Member[]>();

  useEffect(() => {
    if (!chapter) return;
    const getData = async () => {
      const fetchedData = await getChapterMembers(chapter.id);
      const randomSortedData = (fetchedData ?? []).sort(
        () => Math.random() - 0.5,
      );
      setMember(randomSortedData);
    };
    getData();
  }, [chapter]);

  return member;
};

export default useFetchMember;
