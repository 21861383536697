import { useEffect, useState } from "react";
import { getChapterFunctionaries } from "../../http/chapterApi";
import { Chapter, ChapterFunctionary } from "../../types/apiTypes";

const getFullData = async (chapterId: number) => {
  const fetchedData = await getChapterFunctionaries(chapterId);

  const randomSortedData = (fetchedData ?? []).sort(() => Math.random() - 0.5);

  const sorted = randomSortedData.sort((a, b) => {
    const aNum = a.isChapterChairman ? 1 : a.isChapterChairmanDeputy ? 2 : 3;
    const bNum = b.isChapterChairman ? 1 : b.isChapterChairmanDeputy ? 2 : 3;

    return aNum - bNum;
  });

  return sorted;
};

const useFetchCommittee = (chapter: Chapter | undefined) => {
  const [commitee, setCommittee] = useState<ChapterFunctionary[]>();

  useEffect(() => {
    if (!chapter) return;
    const getData = async () => {
      setCommittee(await getFullData(chapter.id));
    };
    getData();
  }, [chapter]);

  return commitee;
};

export default useFetchCommittee;
